import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import {useQuery} from '@apollo/client';
import Logo from '../../assets/logowhite.png';
import SendIcon from '@mui/icons-material/Send';
import {Loading, PAGEScrollDialog} from '../../components/utility/utility';
import {SocialConnect} from '../../components/utility/utility';
import Typography from '@mui/material/Typography';
import { FOOTABOUT, FOOTMEDIACENTER, GETTHEMATICISSUESTITLE, GETFOOTERLEGAL } from '../../components/gpl/gpl';



let displayFooteMenu = <Loading />;
let displayFootThematic = <Loading />;
let displayFootMedia = <Loading />;
let listLegal = <Loading />;

export default function Footer(props) {  //pages.data.pages.nodes
const siteLegal = useQuery(GETFOOTERLEGAL, { fetchPolicy: "cache-and-network" }); 
const footAbout = useQuery(FOOTABOUT, { fetchPolicy: "cache-and-network" }); 
const footMediaCenter = useQuery(FOOTMEDIACENTER, { fetchPolicy: "cache-and-network" }); 
const footThematicIssues = useQuery(GETTHEMATICISSUESTITLE, { fetchPolicy: "cache-and-network" });
if(siteLegal.data) { 
let numbers = siteLegal.data.pages.nodes;
listLegal = numbers.map(number =>
// Correct! Key should be specified inside the array.
<Link key={uuidv4()} onClick={ () => window.topTop() } to={{ pathname: "/con/"+number.pageId+"/"+number.title, }}    className="mb-3 legal defText">{number.title} </Link>    
);}


function uuidv4() {
return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
.replace(/[xy]/g, function (c) {
const r = Math.random() * 16 | 0, 
v = c == 'x' ? r : (r & 0x3 | 0x8);
return v.toString(16);
});
}

if(footAbout.data) { //displayFooteMenu = 'yes'; 
let numberFoot = footAbout.data.pages.nodes;
displayFooteMenu = numberFoot.map(number =>
// Correct! Key should be specified inside the array.
<Link key={uuidv4()}  onClick={ () => window.topTop() } to={{
pathname: "/con/"+number.pageId+"/"+number.title,
}}  className="aboutLinks d-block mb-2 defText">{number.title} </Link>
);  }

if(footMediaCenter.data) { //displayFooteMenu = 'yes'; 
let numberMediaCenter = footMediaCenter.data.categories.nodes;
displayFootMedia = numberMediaCenter.map(number =>
// Correct! Key should be specified inside the array.
<Link key={uuidv4()} onClick={ () => window.topTop() } to={{
pathname: "/cat/"+number.categoryId+"/"+number.name,
}}  className="aboutLinks d-block mb-2 defText">{number.name}</Link>
);  }

if(footThematicIssues.data) { //displayFooteMenu = 'yes'; 
let numberThematic = footThematicIssues.data.categories.nodes;
displayFootThematic = numberThematic.map(number =>
// Correct! Key should be specified inside the array.

<Link key={uuidv4()} onClick={ () => window.topTop() } to={{
pathname: "/subcat/"+number.categoryId+"/"+number.name,
}}  className="aboutLinks d-block mb-2 defText">{number.name}</Link>
);}
const refStart = useRef(document.querySelector('#newsletterStart'));
const refProcess = useRef(document.querySelector('#newsletterProcess'));
const yourEmail = useRef(document.querySelector('#yourEmail'));

useEffect(() => {
 refStart.current = document.querySelector('#newsletterStart');
 refProcess.current = document.querySelector('#newsletterProcess');
 yourEmail.current = document.querySelector('#yourEmail');
   }, []);


async function postData(url = "http://fcdiafrica.org/newsletter/newsletter.php") {
// Default options are marked with *
const formData = new FormData();
formData.append("email", yourEmail.current.value);

const response = await fetch(url, {
method: "POST", // *GET, POST, PUT, DELETE, etc.
mode: "cors", // no-cors, *cors, same-origin
cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
credentials: "same-origin", // include, *same-origin, omit
redirect: "follow", // manual, *follow, error
referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
// body: JSON.stringify(data), // body data type must match "Content-Type" header
body: formData, // body data type must match "Content-Type" header
});
return response.json(); // parses JSON response into native JavaScript objects
}


function signUp(){
refProcess.current.innerHTML = `<div class="alert alert-info" role="alert">
Processing, please wait …
</div>`;
postData().then((data) => {
console.log(data);
if(parseInt(data.status) == 0){refProcess.current.innerHTML = `<div class="alert alert-danger" role="alert">${data.msg}</div>`;}
if(parseInt(data.status) == 1){refProcess.current.innerHTML = `<div class="alert alert-success" role="alert">${data.msg}</div>`;
refStart.current.innerHTML = '';}
 // JSON data parsed by `data.json()` call
//console.log(yourEmail.current.value);
//refProcess.current.innerHTML = `<div class="alert alert-info" role="alert">Processing, please wait … </div>`;
});
}

return (
<>
<section className='footerCon'>
<section className='defPad mt-5'>
<section className="d-flex">
<section className="sideProfile p-4 d-none d-lg-block">
<section className="topLogo mb-3"><Link onClick={ () => window.topTop() } to={{ pathname: "/"}}><img src={Logo} className="img-fluid" alt={props.siteMetaTitle} title={props.siteMetaTitle} />
</Link>
</section>
<section className="defText">{props.siteMetaDescription}</section>
</section>

<section className="flex-fill p-4">
<section className="d-flex justify-items-center align-items-center">
<section className="flex-fill d-none d-md-block px-3 text-center">
<section className="d-flex flex-wrap justify-items-center align-items-center">
<section className="flex-fill  m-2 text-center"><Link onClick={ () => window.topTop() } to={{
pathname: "/con/22/Contact us",
}}  className="mx-2 mb-5">
<Button onClick={ () => window.topTop() } variant="contained" type='button' color='primary' size='small'>Contact us</Button>
</Link></section>

<section className="flex-fill m-2 text-center"><Link to={{
pathname: "/con/37/donate",
}}  className="mx-2 mb-5 realsB2">
<Button onClick={ () => window.topTop() } variant="contained" type='button' color='warning' size='small'>Donate</Button>
</Link></section>
</section>
</section>




<section className="flex-fill px-3">
<section className="catTitle text-center mt-2 mb-2 defText">Connect</section>
<section className="text-center mb-4">
<SocialConnect facebook={true} pinterest={true} x={true} linkedin={true} footer={true} />
</section>
<section className="catTitle text-center mt-2 mb-2 defText">Stay Informed. Signup for Newsletter</section>
<section id='newsletterProcess'></section>
<section id='newsletterStart'>
<div className="input-group">
  <input type="text" className="form-control" placeholder="Enter your email" aria-label="Newsletter Signup" aria-describedby="basic-addon2" id='yourEmail' />
  <div className="input-group-append">
    <button className="btn btn-outline-secondary bg-white pointer" type="button"><SendIcon className='pointer' onClick={() => { signUp()}} /></button>
  </div>
</div></section>

</section>
</section>


<section className="mt-5 mb-3 footerTopDiv pt-3 pb-2">
<section className="d-flex my-3 flex-wrap">
<section className="flex-fill px-3 mb-3">
<section className="fw-bold footHeader mb-3 defText">About</section>
{displayFooteMenu}
</section>

<section className="flex-fill px-3 mb-3">
<section className="fw-bold footHeader mb-3 defText">Media Center </section>
{displayFootMedia}
</section>

<section className="flex-fill px-3 mb-3">
<section className="fw-bold footHeader mb-3 defText">Thematic Issues</section>
{displayFootThematic}
</section>
</section>
</section>

<Typography className="d-flex flex-wrap footerTopDiv mt-3 pt-4">
 {listLegal} 
</Typography>
</section>
</section>
</section>
<section className='d-flex align-items-center footerTopDiv px-5 flex-wrap'>
<Typography className='copy1 defText mt-2 mb-2'>&copy; {new Date().getFullYear()}. {props.siteMetaTitle}. All rigths reserved.  <br /><a className='aboutLinks mx-2' href='http://fcdiafrica.org/wproot/wp-login.php?wp_lang=en_US' target='_blank'>Admin Login</a> <a className='aboutLinks' href='http://webmail.fcdiafrica.org/' target='_blank'>Webmail</a></Typography>

<section className="flex-grow-1">
<section className="d-flex flex-row-reverse align-items-center">
<Typography className="copy1 mt-2 mb-2">Designed By <a rel="noopener noreferrer" className='aboutLinks' href='http://dredge.com.ng/' target='_blank'>Dredge Resources </a> </Typography>
</section>
</section>
</section>

</section>
</>
);}
