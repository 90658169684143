import React from 'react';
import './App.css';
import { BrowserRouter} from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import Container from './components/container/container';


var chatServer = "https://admin.fcdiafrica.org/graphql"; 
var token = "none";
var apiToken = "none";


const httpLink = createHttpLink({ uri: chatServer,});
const authLink = setContext((_, { headers }) => {
return { headers: { ...headers, authorization: token ? `Bearer ${token} ${apiToken}` : "", },
fetchOptions: {
         //mode: 'no-cors', // no-cors, *cors, same-origin
      }
	  } });
const client = new ApolloClient({ link: authLink.concat(httpLink), cache: new InMemoryCache(), connectToDevTools: true })


function App() { 
return (
<BrowserRouter>
<ApolloProvider client={client}>
<Container />
</ApolloProvider>
</BrowserRouter>
);
}
export default App;
  