import React, { useState, useRef, useEffect } from 'react';
import {useQuery} from '@apollo/client';
import Tiktok from '../../assets/tiktok.png';
import { useNavigate, useParams} from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Card from 'react-bootstrap/Card';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Office from '../../assets/office.png';
import Donate from '../../assets/donate.png';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';

//import { styled } from '@mui/material/styles';
//import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Helmet} from "react-helmet";
import { PAGEDETAILS, POSTDETAILS } from '../../components/gpl/gpl';



import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

function Loading() {
  return (
  <Box sx={{ width: '100%' }}>
  <Skeleton height={6} className='mb-2' animation="wave" sx={{ animationDuration: "0.3s", 
  backgroundColor: '#226134', '&::after': { backgroundColor: '#AAA7A7', }, }} />
  <Skeleton height={6} className='mb-2' animation="wave" sx={{
  backgroundColor: '#226134', '&::after': { backgroundColor: '#AAA7A7', }, }} />
  <Skeleton height={6} className='mb-2' animation="wave" sx={{
  backgroundColor: '#226134', '&::after': { backgroundColor: '#AAA7A7', }, }} />
  <Skeleton height={6} className='mb-2' animation="wave" sx={{
  backgroundColor: '#226134', '&::after': { backgroundColor: '#AAA7A7', }, }} />
  </Box>
  );
  }

function Loading1(props) {
return (
<Box sx={{ width: '100%' }}>
<Skeleton height={14} className='mb-2' animation="wave" sx={{
backgroundColor: '#F5F5F5', '&::after': { backgroundColor: '#ffffff', }, }} />
<Skeleton height={14} className='mb-2' animation="wave" sx={{
backgroundColor: '#F5F5F5', '&::after': { backgroundColor: '#ffffff', }, }} />
<Skeleton height={14} className='mb-2' animation="wave" sx={{
backgroundColor: '#F5F5F5', '&::after': { backgroundColor: '#ffffff', }, }} />
<Skeleton height={14} className='mb-2' animation="wave" sx={{
backgroundColor: '#F5F5F5', '&::after': { backgroundColor: '#ffffff', }, }} />
</Box>
);
}
 
function Counter(props) { 
const [startTime, setStartTime] = useState(null);
const [now, setNow] = useState(null);
const intervalRef = useRef(null);
const ref = useRef(0);
const refbox = useRef(null);

function handleStart() {
setStartTime(0);
if(parseInt(ref.current) < parseInt(props.value)){setNow(ref.current+1);}

clearInterval(intervalRef.current);
intervalRef.current = setInterval(() => {
if(parseInt(ref.current) < parseInt(props.value)){
ref.current=ref.current+1;
setNow(ref.current);
}

if(parseInt(ref.current) > parseInt(props.value)){
clearInterval(intervalRef.current);
document.removeEventListener('scroll', handleStop, { passive: true });
}
}, 10); }

function handleStop() { clearInterval(intervalRef.current); }
let secondsPassed = 0;
if (startTime != null && now != null) { secondsPassed = (now - startTime); }



function isInViewport(box) { 
const rect = box.getBoundingClientRect();
let result = rect.top>= 0 &&
rect.left>= 0 &&
rect.bottom<= (window.innerHeight || document.documentElement.clientHeight) &&
rect.right<= (window.innerWidth || document.documentElement.clientWidth);
return result;
}

useEffect(() => {
refbox.current = document.querySelector('#'+props.id);
if(isInViewport(refbox.current)){handleStart();}
document.addEventListener('scroll', function () {
if(isInViewport(refbox.current)){handleStart();}
}, { passive: true });
return () => {
document.removeEventListener('scroll', handleStop, { passive: true }); 
}; }, []);
return (
<center>
<Card sx={{ maxWidth: 345, border:0 }}>
<section className='p-3 text-center'>
<section><img src={props.IMG} className="img-fluid" alt="Nile" /></section>

<CardContent>
<Typography gutterBottom variant="h5" component="div">
{props.title}
</Typography>
</CardContent>
<Typography variant="body2" color="text.secondary" className='mx-3'>
<span className='p-2 counterNumber' id={props.id}>{secondsPassed}</span>
</Typography>
</section>
</Card>
</center>
);}

function Partner(props) { 
return (<>
<section className='px-4 partnerLogo'><img src={props.img} className="img-fluid" alt={props.title} />
</section>
<section className='px-4 partnerTitle fw-bold' dangerouslySetInnerHTML={createMarkup(props.body)}></section></>
);}

function HomeIntro2(props) { 
return (<>
<section style={{backgroundImage:'url('+props.img+')', height: '240px',
backgroundPosition: 'top',
backgroundSize: 'cover', backgroundRepeat:'no-repeat'}} className='d-flex align-items-end'>
<section className='blogTitle flex-fill p-2 px-3 text-center'>
<Link to={{
pathname: "/article/"+props.id+"/"+props.title,
}}  className="px-1 catLink mb-2">{props.title}</Link></section>
</section>
</>
);}
function SectionDivider(props){
  return(<section className='d-flex align-items-center p-2 fw-bold dividerCon'>
  <section className='px-3'>{props.title} </section>
  <section className='flex-grow-1'><hr /></section>
</section>);
}
function CatList(props) { 
  return (<section style={{backgroundImage:'url('+props.img+')', height: '240px',
    backgroundPosition: 'top',
    backgroundSize: 'cover', width:'240px', backgroundRepeat:'no-repeat'}} className='d-flex align-items-end'>
    <section className='blogTitle flex-fill p-2 px-3 text-center'>
    <Link to={{
pathname: "/article/"+props.id+"/"+props.title,
}}  className="px-1 catLink mb-2">{props.title}</Link></section>
    </section> );}

function SubCatList(props) { 
   
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
let returnVal = '';
  if(props.categoryId == props.catid){
    if(parseInt(props.posts.nodes.length) > 0){
      let numbers = props.posts.nodes;
      returnVal = numbers.map(number =>
      // Correct! Key should be specified inside the array.
      <section key={uuidv4()} style={{backgroundImage:'url('+(number.featuredImage != null ? number.featuredImage.node.sourceUrl : null)+')', height: '240px',
    backgroundPosition: 'top',
    backgroundSize: 'cover', width:'240px', backgroundRepeat:'no-repeat'}} className='d-flex align-items-end mb-5'>
    <section className='blogTitle flex-fill p-2 px-3 text-center'>
    <Link to={{
pathname: "/article/"+number.postId+"/"+number.title,
}}  className="px-1 catLink mb-2">{number.title}</Link></section>
    </section>   
      );}}
  
return (<section className="d-flex gap-5 flex-wrap justify-content-center">{returnVal}</section>);}
function ImgCard(props) {
return (<>
<section className=''>
<section>
<Link to={{
pathname: "/article/"+props.id+"/"+props.title,
}} className="px-1 catLink mb-2 homeTitle"><img src={props.img} className='img-fluid' alt={props.title} title={props.title} /></Link></section>
<section className='p-2 px-3 fw-bold'>
<Link to={{
pathname: "/article/"+props.id+"/"+props.title,
}} className="px-1 catLink mb-2 homeTitle">{props.title}</Link>
</section>
<section className='mx-2' dangerouslySetInnerHTML={createMarkup(props.excerpt)}></section>
</section>
</>
);
}

function createMarkup(x) {  return {__html: x}; }
function PAGEScrollDialog(props) {
  const getPage = useQuery(PAGEDETAILS, {variables: { id: parseInt(props.id) }, fetchPolicy: "cache-and-network" }); 
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  let displayContent = <Loading1 />
  
  if(getPage.data){
  displayContent = <span  dangerouslySetInnerHTML={createMarkup(getPage.data.pages.nodes[0].content)}></span>;
  }
  const handleClickOpen = (scrollType) => () => {
  setOpen(true); setScroll(scrollType); };
  const handleClose = () => { setOpen(false); };
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
  if (open) {
  const { current: descriptionElement } = descriptionElementRef;
  if (descriptionElement !== null) {
  descriptionElement.focus();
  }
  }
  }, [open]);
  return (
  <React.Fragment>
  <span className='pointer' onClick={handleClickOpen('body')}>{props.title}</span>
  <Dialog
  open={open}
  onClose={handleClose}
  scroll={scroll}
  aria-labelledby="scroll-dialog-title"
  aria-describedby="scroll-dialog-description"
  fullWidth={fullWidth}
  maxWidth={maxWidth}
  >
  <DialogTitle id="scroll-dialog-title">
  <section className="d-flex overlayCon">
  <h1 className='overlayTitle fw-bold'>{props.title} </h1>
  <section className="flex-grow-1 d-flex flex-row-reverse ">
  <section onClick={handleClose} className='icon'><CloseIcon /></section>
  </section></section>
  </DialogTitle>
  
  <DialogActions>
  <DialogContent dividers={scroll === 'paper'}>
  <DialogContentText
  id="scroll-dialog-description"
  ref={descriptionElementRef}
  tabIndex={-1}
  >
  <section className='px-3 m-0 fw-bold'> {displayContent} </section>
  </DialogContentText>
  </DialogContent>
  </DialogActions>
  </Dialog>
  </React.Fragment>
  );
  }   
function POSTScrollDialog(props) { 
  const getPage = useQuery(POSTDETAILS, {variables: { id: parseInt(props.id) }, fetchPolicy: "cache-and-network" }); 
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  let displayContent = <Loading1 />
  function createMarkup(x) {  return {__html: x}; }
  
  if(getPage.data){
  displayContent = <section  dangerouslySetInnerHTML={createMarkup(getPage.data.posts.nodes[0].content)}></section>;
  }
  const handleClickOpen = (scrollType) => () => {
  setOpen(true);
  setScroll(scrollType);
  };
  
  const handleClose = () => {
  setOpen(false);
  };
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
  if (open) {
  const { current: descriptionElement } = descriptionElementRef;
  if (descriptionElement !== null) {
  descriptionElement.focus();
  }
  }
  }, [open]);
  return (
  <React.Fragment>
  <span className='pointer' onClick={handleClickOpen('body')}>{props.title}</span>
  <Dialog
  open={open}
  onClose={handleClose}
  scroll={scroll}
  aria-labelledby="scroll-dialog-title"
  aria-describedby="scroll-dialog-description"
  fullWidth={fullWidth}
  maxWidth={maxWidth}
  >
  <DialogTitle id="scroll-dialog-title">
  <section className="d-flex overlayCon">
  <h1 className='overlayTitle fw-bold'>{props.title} </h1>
  <section className="flex-grow-1 d-flex flex-row-reverse ">
  <section onClick={handleClose} className='icon'><CloseIcon /></section>
  </section></section>
  </DialogTitle>
  
  <DialogActions>
  <DialogContent dividers={scroll === 'paper'}>
  <DialogContentText
  id="scroll-dialog-description"
  ref={descriptionElementRef}
  tabIndex={-1}
  >
  <Typography className='px-3 m-0 fw-bold'> {displayContent} </Typography>
  </DialogContentText>
  </DialogContent>
  </DialogActions>
  </Dialog>
  </React.Fragment>
  );
  }


function AddToAny(props) { 
const ref = useRef(null);
function displayAddToAny(){
if(document.querySelector('#addToAny')){
  ref.current.innerHTML = `<div class="a2a_kit a2a_kit_size_32 a2a_default_style">
  <a class="a2a_button_facebook"></a>
  <a class="a2a_button_pinterest"></a>  
  <a class="a2a_button_x"></a>
  <a class="a2a_button_linkedin"></a>
  <a class="a2a_button_whatsapp"></a>
  <a class="a2a_button_email"></a>
  </div>
  <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
</div>`; } 
}


useEffect(() => {
  ref.current = document.querySelector('#addToAny');
  displayAddToAny();
   }, []);

return (<><section id='addToAny'></section>{displayAddToAny()}</>);}

function SocialConnect(props) { 
let iconClass = (props.footer != 'undefined' && props.footer == true) ? 'iconFooter mx-1' : 'icon mx-1';
return (<>
{(props.facebook != 'undefined' && props.facebook == true) ? <a href='https://web.facebook.com/fcdiafrica' rel="noopener" target="_blank" className={iconClass} title='FaceBook'><FacebookIcon /></a> : null}

{(props.linkedin != 'undefined' && props.linkedin == true) ? <a href='https://www.linkedin.com/in/fcdi' rel="noopener" target="_blank" className={iconClass} title='LinkedIn'><LinkedInIcon /></a> : null}

{(props.x != 'undefined' && props.x == true) ? <a href='https://twitter.com/fcdiafrica' rel="noopener" target="_blank" title='X' className={iconClass}><XIcon /></a> : null}

{(props.tiktok != 'undefined' && props.tiktok == true) ? <a href='https://www.tiktok.com/@fcdiafrica' rel="noopener" target="_blank" className={iconClass}><img src={Tiktok} className='img-fluid iconImg' title='Tiktok' /></a> : null}

{(props.pinterest != 'undefined' && props.pinterest == true) ? <a href='https://www.pinterest.com/fcdiafrica' rel="noopener" target="_blank" className={iconClass} title='Pinterest'><PinterestIcon /></a> : null}

</>);}
function Content(props) {  
const [formState, setFormState] = useState({
  oneTime: '#EFF6FC',
  monthly: '',
  selectedAmt: 10,
  donateOnline: true,
  });

let { id } = useParams();
let title = '';
const getPage = useQuery(PAGEDETAILS, {variables: { id: parseInt(id) }, fetchPolicy: "cache-and-network" });  
const getDonate = useQuery(PAGEDETAILS, {variables: { id: parseInt(221) }, fetchPolicy: "cache-and-network" });   
let displayContent = <Loading1 />    
let displayDonateOffline = <Loading1 />  
if(getPage.data){
displayContent = <span  dangerouslySetInnerHTML={createMarkup(getPage.data.pages.nodes[0].content)}></span>;  
title = getPage.data.pages.nodes[0].title;
}
if(getDonate.data){
  
if(parseInt(getDonate.data.pages.nodes.length) > 0){
  displayDonateOffline = <section dangerouslySetInnerHTML={createMarkup(getDonate.data.pages.nodes[0].content)}></section>
   } else {displayDonateOffline = '';}
  }
const navigate = useNavigate();
function updateMBtn(x,y=0){ 
if(parseInt(x) == 1){setFormState({ ...formState, monthly: '', oneTime: '#EFF6FC', }); }
if(parseInt(x) == 2){setFormState({ ...formState, monthly: '#EFF6FC', oneTime: '', }); }
if(parseInt(x) == 3){setFormState({ ...formState, selectedAmt: y, }); }
}


function donateState(){ 
setFormState({ ...formState, donateOnline: !formState.donateOnline, }); 
}


return (
<section className="defMrg mt-3 pt-2">
<section className="d-flex align-items-center pageTitle pb-1 mt-2 mb-4">
<section className='pointer align-self-center' onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></section>
<section className='fw-bold mx-3 align-self-center'>{title}</section>
</section>

{parseInt(id) == 22 ? <section className='row'>
<section className='col-md-6 p-5'><img src={Office} className='img-fluid' title='Foundation for Community Development Initiative' alt='Foundation for Community Development Initiative' /></section>
<section className='col-md-6 p-3'>{displayContent}</section>  
</section> : null}

{parseInt(id) == 37 ? <section className='row'>
<section className='col-md-6 p-5'><img src={Donate} className='img-fluid' title='Foundation for Community Development Initiative' alt='Foundation for Community Development Initiative' /></section>
<section className='col-md-6 p-3 px-5'>
<section className='mb-4'><h2>Donate Now</h2></section> 
{formState.donateOnline == true ? <section> 
<section>
<Button onClick={() => { updateMBtn(1)}} sx={{
          bgcolor: formState.oneTime,
          boxShadow: 2,
        }} className='mx-1 fw-bold mb-2' variant="outlined" startIcon={<DoneIcon />}>One Time</Button>
<Button onClick={() => {updateMBtn(2) }} sx={{
          bgcolor: formState.monthly,
          boxShadow: 2,
        }} className='mx-1 fw-bold mb-2' variant="outlined" startIcon={<FavoriteIcon />}>Monthly</Button>
</section>  
<section className='row mb-2 mt-5 px-2'>
<section className='col-md-4 px-2 m-0 pointer'><section onClick={() => { updateMBtn(3,10)}} className='amt p-2'>US10</section></section>  
<section className='col-md-4 px-2 m-0 pointer'><section onClick={() => { updateMBtn(3,50)}}  className='amt p-2'>US50</section></section>  
<section className='col-md-4 px-2 m-0 pointer'><section onClick={() => { updateMBtn(3,150)}} className='amt p-2'>US150</section></section>  
</section>  

 
<section className='row mb-5 mt-1 px-2'>
<section className='col-md-4 px-2 m-0 pointer'><section onClick={() => { updateMBtn(3,250)}} className='amt p-2'>US250</section></section>  
<section className='col-md-4 px-2 m-0 pointer'><section onClick={() => { updateMBtn(3,400)}} className='amt p-2'>US400</section></section>  
<section className='col-md-4 px-2 m-0 pointer'><section onClick={() => { updateMBtn(3,750)}} className='amt p-2'>US750</section></section>  
</section>  

<h5 className='mb-2 fw-bold'>Selected Amount</h5>
<h4 className='mb-2 fw-bold partnerTitle'>USD{formState.selectedAmt}</h4>
<section className='mt-3'>
<Button variant="contained" type='button' color='primary' size='small' className='p-2 px-3 m-1'>
  Donate and Support
</Button>
<Button onClick={() => {donateState() }} variant="outlined" type='button' color='info' size='small' className='p-2 px-3 m-1'>
Other ways to give
</Button>
</section> 
</section> : <section>
<h5 className='mb-2 fw-bold'>Instructions for bank transfer / deposits</h5>
<section className='m-3'>
<section className='mb-4 mt-4'>{displayDonateOffline}</section>
<Button onClick={() => {donateState() }} variant="outlined" type='button' color='info' size='small' className='p-2 px-3 m-1'>
  Give online
</Button>
</section> 
</section> }
</section>
</section> : null}
{(parseInt(id) != 37 && parseInt(id) != 22) ? <section>{displayContent}</section> : null}
<Helmet>
<title>{title}</title>
</Helmet>
</section>
  );
  } 


function Article(props) {  
let { id } = useParams();
const navigate = useNavigate();
let title = '';
const getPage = useQuery(POSTDETAILS, {variables: { id: parseInt(id) }, fetchPolicy: "cache-and-network" });   
let displayContent = <Loading1 />  
if(getPage.data){
  
displayContent = (<><section className='mb-5 articleImg text-center'><img src={getPage.data.posts.nodes[0].featuredImage.node.sourceUrl} border='0' className='img-fluid articleImg' /></section><section dangerouslySetInnerHTML={createMarkup(getPage.data.posts.nodes[0].content)} className='mb-3'></section></>);  
title = getPage.data.posts.nodes[0].title;
}

return (
<section className="defMrg mt-3 pt-2">
<section className="d-flex align-items-center pageTitle pb-1 mt-2 mb-4">
<section className='pointer align-self-center' onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></section>
<section className='fw-bold mx-3 align-self-center'>{title}</section>
</section>
<section>{displayContent}</section>
<Helmet>
<title>{title}</title>
</Helmet>
</section>
);
} 


export {Loading, AddToAny, PAGEScrollDialog, Counter, Loading1, Partner, ImgCard, POSTScrollDialog, Article, HomeIntro2, SectionDivider, CatList, SubCatList, SocialConnect, Content}