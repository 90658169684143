import React, { useState, useRef, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {useQuery} from '@apollo/client';
import { COVERIMAGE, WELCOME, HERO, LATESTBLOG, PARTNERS, GETTHEMATICISSUESTITLE } from '../../components/gpl/gpl';
import {Counter, Loading1, ImgCard, Partner, HomeIntro2, POSTScrollDialog} from '../../components/utility/utility';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Carousel from 'react-bootstrap/Carousel';
import check from '../../assets/check.png';
import comm from '../../assets/community.png';
import partner from '../../assets/partner.png';
import {Helmet} from "react-helmet";

export default function Home(props) { 
const coverImage = useQuery(COVERIMAGE, { fetchPolicy: "cache-and-network" });  
const welcomeMsg = useQuery(WELCOME, { fetchPolicy: "cache-and-network" });  
const footThematicIssues = useQuery(GETTHEMATICISSUESTITLE, { fetchPolicy: "cache-and-network" });
const heroImages = useQuery(HERO, {variables: { id: parseInt(25) }, fetchPolicy: "cache-and-network" }); 
const events = useQuery(HERO, {variables: { id: parseInt(8) }, fetchPolicy: "cache-and-network" }); 
const partners = useQuery(PARTNERS, {variables: { id: parseInt(17) }, fetchPolicy: "cache-and-network" });   
const latestBlog = useQuery(LATESTBLOG, { fetchPolicy: "cache-and-network" });  

const [formState, setFormState] = useState({ coverTitle: '', sourceUrl: '', welcome:'', hero1: false,hero2: false, hero3: false, blog1: 0, blog1Title: '', blog1Id: '', blog1Img:'', blog2: 0, blog2Title: '', blog2Id: '', blog2Img:'', blog3: 0, blog3Title: '', blog3Id: '', blog3Img:'', blog4: 0, blog4Title: '', blog4Id: '', blog4Img:'', });
if(coverImage.data && welcomeMsg.data && formState.coverTitle==''){ 
setFormState({
...formState, coverTitle: coverImage.data.posts.nodes[0].title,
sourceUrl: coverImage.data.posts.nodes[0].featuredImage.node.sourceUrl,
welcome: welcomeMsg.data.pages.nodes[0].content,
})
}

function createMarkup(x) { return {__html: x}; }
 
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
let tmpHero1, tmpHero2, tmpHero3, event1=<Loading1 />, event2=<Loading1 />, event3=<Loading1 />, partnerCon=<Loading1 />;
if(heroImages.data ){
if(heroImages.data.posts.nodes.length > 1 && heroImages.data.posts.nodes[1].featuredImage != null){
tmpHero1 = 
<Carousel.Item>
<img src={heroImages.data.posts.nodes[1].featuredImage.node.sourceUrl} className='img-fluid' />
<Carousel.Caption>
<h3>
<Link to={{
pathname: "/article/"+heroImages.data.posts.nodes[1].postId+"/"+props.title,
}}  className="px-1 catLink mb-2">{heroImages.data.posts.nodes[1].title}</Link>
</h3>
</Carousel.Caption>
</Carousel.Item>
;
}

if(heroImages.data.posts.nodes.length > 0 && heroImages.data.posts.nodes[0].featuredImage != null){
tmpHero2 = <Carousel.Item> 
<img src={heroImages.data.posts.nodes[0].featuredImage.node.sourceUrl} className='img-fluid' />
<Carousel.Caption>
<h3>
  
<Link to={{
pathname: "/article/"+heroImages.data.posts.nodes[0].postId+"/"+props.title,
}}  className="px-1 catLink mb-2">{heroImages.data.posts.nodes[0].title}</Link>
 </h3>
</Carousel.Caption>
</Carousel.Item> ;
}


if(heroImages.data.posts.nodes.length > 2 && heroImages.data.posts.nodes[2].featuredImage != null){
tmpHero3 = <Carousel.Item> 
<img src={heroImages.data.posts.nodes[2].featuredImage.node.sourceUrl} className='img-fluid' />
<Carousel.Caption>  
<h3>
  
<Link to={{
pathname: "/article/"+heroImages.data.posts.nodes[2].postId+"/"+props.title,
}}  className="px-1 catLink mb-2">{heroImages.data.posts.nodes[2].title}</Link>
 </h3>
</Carousel.Caption>
</Carousel.Item> ;
} }

if(events.data){ 
if(events.data.posts.nodes.length > 1 && events.data.posts.nodes[1].featuredImage != null && events.data.posts.nodes[1].featuredImage != 'null'){
event1 = <section className='px-3 flex-fill col'><ImgCard img={events.data.posts.nodes[1].featuredImage.node.sourceUrl} excerpt={events.data.posts.nodes[1].excerpt} id={events.data.posts.nodes[1].postId} title={events.data.posts.nodes[1].title} date={events.data.posts.nodes[1].slug} /></section>
} else {  event1=null;}

if(events.data.posts.nodes.length > 0 && events.data.posts.nodes[0].featuredImage != 'null' && events.data.posts.nodes[0].featuredImage != null){
event2 = <section className='flex-fill col px-3'>
<ImgCard img={events.data.posts.nodes[0].featuredImage.node.sourceUrl} excerpt={events.data.posts.nodes[0].excerpt} title={events.data.posts.nodes[0].title} id={events.data.posts.nodes[0].postId} date={events.data.posts.nodes[0].slug} /></section>
} else {  event2=null;}

if(events.data.posts.nodes.length > 2 && events.data.posts.nodes[2].featuredImage != 'null' && events.data.posts.nodes[2].featuredImage != null){
event3 = <section className='flex-fill col px-3'><ImgCard img={events.data.posts.nodes[2].featuredImage.node.sourceUrl} excerpt={events.data.posts.nodes[2].excerpt} title={events.data.posts.nodes[2].title} id={events.data.posts.nodes[2].postId} date={events.data.posts.nodes[2].slug} /></section>
} else {  event3=null;} }


if(partners.data) { 
let partnerNumbers = partners.data.posts.nodes;
partnerCon = partnerNumbers.map(number =>
// Correct! Key should be specified inside the array.
<span key={uuidv4()}>
<Partner body={number.content} img={number.featuredImage.node.sourceUrl} title={number.title} /></span>    
);
}
// start

if(latestBlog.data ){
  if(latestBlog.data.posts.nodes.length > 0 && latestBlog.data.posts.nodes[0].featuredImage != null){
  if(formState.blog1 != 1 && formState.blog1 != 2){
  setFormState({   ...formState, blog1: 2, blog1Title: latestBlog.data.posts.nodes[0].title, blog1Id: latestBlog.data.posts.nodes[0].postId, blog1Img:latestBlog.data.posts.nodes[0].featuredImage.node.sourceUrl,   })
  }}   
  
  
  if(latestBlog.data.posts.nodes.length > 1 && latestBlog.data.posts.nodes[1].featuredImage != null){
  if(formState.blog2 != 1 && formState.blog2 != 2){
  setFormState({   ...formState, blog2: 2, blog2Title: latestBlog.data.posts.nodes[1].title, blog2Id: latestBlog.data.posts.nodes[1].postId, blog2Img:latestBlog.data.posts.nodes[1].featuredImage.node.sourceUrl,   })
  }}   
  
  
  
  if(latestBlog.data.posts.nodes.length > 2 && latestBlog.data.posts.nodes[2].featuredImage != null){
  if(formState.blog3 != 1 && formState.blog3 != 2){
  setFormState({   ...formState, blog3: 2, blog3Title: latestBlog.data.posts.nodes[2].title, blog3Id: latestBlog.data.posts.nodes[2].postId, blog3Img:latestBlog.data.posts.nodes[2].featuredImage.node.sourceUrl,   })
  }}   
  
  
  
  if(latestBlog.data.posts.nodes.length > 3 && latestBlog.data.posts.nodes[3].featuredImage != null){
  if(formState.blog4 != 1 && formState.blog4 != 2){
  setFormState({   ...formState, blog4: 2, blog4Title: latestBlog.data.posts.nodes[3].title, blog4Id: latestBlog.data.posts.nodes[3].postId, blog4Img:latestBlog.data.posts.nodes[3].featuredImage.node.sourceUrl,   })
  }}}

  
let displayFootThematic=<Loading1 />;
if(footThematicIssues.data) { //displayFooteMenu = 'yes'; 
let numberThematic = footThematicIssues.data.categories.nodes;
displayFootThematic = numberThematic.map(number =>
<Link key={uuidv4()} onClick={ () => window.topTop() } to={{
pathname: "/subcat/"+number.categoryId+"/"+number.name,
}}  className="intro1 d-block mb-2">{number.name}</Link>
);
}

return (
<>
<section className='p-5 m-4 d-block d-lg-none' style={{backgroundImage:'url('+formState.sourceUrl+')', height:'400px', backgroundSize: 'cover', opacity:0.9, backgroundRepeat:'no-repeat'}}>
<section className='p-5'>
<h1 className='p-5 text-center' style={{backgroundColor:'#767876', opacity:0.9, color:'#ffffff', maxWidth:'90%'}}>{formState.coverTitle}</h1>
</section>
</section>

<section className='p-5 d-none d-lg-block' style={{backgroundImage:'url('+formState.sourceUrl+')', height:'400px', backgroundSize: 'cover', opacity:0.9, backgroundRepeat:'no-repeat'}}>
<section className='p-5'>
<h1 className='p-5 text-center' style={{backgroundColor:'#767876', opacity:0.9, color:'#ffffff', maxWidth:'50%'}}>{formState.coverTitle}</h1>
</section>
</section>
<section dangerouslySetInnerHTML={createMarkup(formState.welcome)} className='m-5 pt-5 pb-5'></section>


<section className='mb-4'>
<section className='defMrg mb-4 mt-4'>
<Divider 
sx={{
color: '#09A1D3',
textTransform:'uppercase',
fontSize:'20px',
fontWeight:'bold',
marginBottom:'5%',
marginTop:'5%',
  }} textAlign='left'>Programmes</Divider>
</section>


<section className='col text-center d-block d-lg-none p-5'>
<Carousel> 
{tmpHero1} 
{tmpHero2} 
{tmpHero3} 
</Carousel> 
</section>

<section id='programs' className='defPad d-flex pb-4 pt-4 mb-5 mt-5 align-items-center'>
<section className='col text-center d-none d-lg-block p-5'>
<Carousel> 
{tmpHero1} 
{tmpHero2} 
{tmpHero3} 
</Carousel> 
</section>
<section className='col'>
<Typography gutterBottom variant="h5" component="div">
{props.siteMetaDescription}
</Typography>
{displayFootThematic}
</section>
</section>
</section>

<section className='defMrg sectionCon'>
<Divider 
sx={{
color: '#09A1D3',
textTransform:'uppercase',
fontSize:'20px',
fontWeight:'bold',
marginBottom:'5%',
marginTop:'5%',
        }} textAlign='left'>Latest Blogs</Divider>


<section className="row">
{formState.blog1 == 0 ? <section className="col-md mb-5 m-0 p-0 px-5"><Loading1 /></section> : formState.blog1 == 1 ? null : <section className="col-md mb-4 m-0 p-0 px-2"><HomeIntro2 img={formState.blog1Img} title={formState.blog1Title} id={formState.blog1Id} /></section>}

{formState.blog2 == 0 ? <section className="col-md mb-5 m-0 p-0 px-5"><Loading1 /></section> : formState.blog2 == 1 ? null : <section className="col-md mb-4 m-0 p-0 px-2"><HomeIntro2 img={formState.blog2Img} title={formState.blog2Title} id={formState.blog2Id} /></section>}

{formState.blog3 == 0 ? <section className="col-md mb-5 m-0 p-0 px-5"><Loading1 /></section> : formState.blog3 == 1 ? null : <section className="col-md mb-4 m-0 p-0 px-2"><HomeIntro2 img={formState.blog3Img} title={formState.blog3Title} id={formState.blog3Id} /></section>}

{formState.blog4 == 0 ? <section className="col-md mb-4 m-0 p-0 px-5 d-none d-lg-block"><Loading1 /></section> : formState.blog4 == 1 ? null : <section className="col-md m-0 p-0 px-2 d-none d-lg-block"><HomeIntro2 img={formState.blog4Img} title={formState.blog4Title} id={formState.blog4Id} /></section>}
</section>




<section className='sectionCon'>
<Divider 
sx={{
color: '#09A1D3',
textTransform:'uppercase',
fontSize:'20px',
fontWeight:'bold',
marginBottom:'5%',
marginTop:'5%',
        }} textAlign='left'>Progress</Divider>


<section className='row mt-3 mb-5 align-item-center'>
<section className='col-md-4 mb-5 px-3'><Counter IMG={comm} value={18} title='Communities' id='counter1' /></section>
<section className='col-md-4 mb-5 px-3'><Counter IMG={partner} value={4} title='Partners' id='counter2' /></section>
<section className='col-md-4 mb-5 px-3'><Counter IMG={check} value={14} title='Programmes' id='counter3' /></section>
</section>
</section>
<Divider 
sx={{
color: '#09A1D3',
textTransform:'uppercase',
fontSize:'20px',
fontWeight:'bold',
marginBottom:'5%',
marginTop:'5%',
}} textAlign='left'>Events</Divider>


<section className="row gap-5 mb-5">
<section className="col-md p-3">
<div className="mt-2">{event2}</div>
</section>
<section className="col-md p-3">
<div className="mt-2">{event1}</div>
</section>
<section className="col-md p-3">
<div className="mt-2">{event3}</div>
</section>
</section>


<Divider 
sx={{
color: '#09A1D3',
textTransform:'uppercase',
fontSize:'20px',
fontWeight:'bold',
marginBottom:'5%',
marginTop:'9%',
}}>Partners</Divider>

<section className='d-flex align-items-center justify-content-center'>{partnerCon}</section>





</section>

<Helmet>
<title>Foundation for Community Development Initiative</title>
</Helmet>
</>
);}

